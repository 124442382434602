(function ($) {

    $(function () {
        new Swiper('.swiper-container', {
            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 6000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            },
        });

        new Swiper('.logo-swiper', {
            slidesPerView: 5,
            spaceBetween: 30,
            loop: true,
            autoplay: {
                delay: 8000,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 5,
                },
                992: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
            },
        });

        $('.gallery').each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true,
                },
            });
        });

        $('[data-toggle]').click(function (e) {
            e.preventDefault();
            var selector = $(this).attr('data-toggle');
            $(selector).toggleClass('-open');
        });
    });

    /**
     * Animation für Zahlen-ContentBlock
     */
    var elems = $('div.zahlen div.wert');

    $.each(elems, function (i, elem) {
        var $elem = $(elem);
        var impressionRecorded = false;
        var endVal = $elem.html().replace('.', '');
        var target = $elem.attr('id');
        $elem.html('0');

        $(document).scroll(function () {
            if (isInView($elem) && !impressionRecorded) {
                impressionRecorded = true;
                startAnimation(target, endVal);
            }
        });
    });

    function startAnimation (target, endVal) {
        window.setTimeout(function () {
            var numAnim = new CountUp(target, 0, endVal);
            if (!numAnim.error) {
                numAnim.start();
            } else {
                console.error(numAnim.error);
            }
        }, 100);
    }

    function isInView ($elem) {
        var y = $elem.offset().top;
        var windowY = $(window).scrollTop();
        return (y > windowY) && (y < windowY + $(window).height());
    }

    /**
     * Toggle body-scrolled on body depending on scroll position
     */
    $(document).ready(function () {
        toggleBodyClasses();
    });

    $(window).scroll(function () {
        toggleBodyClasses();
    });

    function toggleBodyClasses () {
        if ($(window).scrollTop() > 10) {
            $('body').addClass('body-scrolled');
        } else {
            $('body').removeClass('body-scrolled');
        }
    }

})(jQuery);
